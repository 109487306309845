<template>
    <div class="container rtl">
        <div class="row pb-3">
            <div class="col">
                <form @submit.prevent="adduser" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>زیاکردنی بەکارهێنەر</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable"  >
                            </label> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>ناوی بەکارهێنەر</label>
                            <input v-model="users.user_name" class="form-control" placeholder="ناوی بەکارهێنەر"
                                type="text" required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> وشەنهێنی </label>
                            <input v-model="users.user_password" class="form-control" placeholder="وشەنهێنی  "
                                type="text" required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label>ژمارەی مۆبایل </label>
                            <input v-model="users.user_phone" class="form-control" placeholder="ژمارەی مۆبایل "
                                type="text" required>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> ڕۆڵ </label>
                            <select v-model="users.user_role" class="form-control" required>
                                <option value="super">بەڕێوەبەر</option>
                                <option value="admin">ژمێریار</option>
                                <option value="company">کۆمپانیا</option>
                                <option value="casher">فرۆشیار</option>
                            </select>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                            <label> مەخزەن </label>
                            <select v-model="users.store_id" class="form-control" required>
                                <option :value="item.store_id" v-for="(item, index) in store" :key="index">{{item.store_name}}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary m-2">زیادکردن</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-striped" id="dataTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">ناوی بەکارهێنەر</th>
                            <th scope="col">ڕۆڵ</th>
                            <th scope="col">ژمارەی مۆبایل</th>
                            <th scope="col">مەخزەن</th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in allusers" :key="index"
                            :class="item.user_status == 'active' ? '' : 'text-danger'">
                            <td>{{item.user_name}}</td>
                            <td>{{item.user_role}}</td>
                            <td>{{item.user_phone}}</td>
                            <td>{{item.store_name}} ({{item.city_name_ku}})</td>
                            <td>
                                <button @click="openuser(item , 'remove')" type="button" class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="openuser(item , 'update')" type="button" class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی بەکارهێنەر</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی بەکارهێنەر؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deleteuser">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updateuser">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی بەکارهێنەر</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <div class="col-6 my-1">
                                    <label>ناوی بەکارهێنەر</label>
                                    <input v-model="selected_users.user_name" class="form-control form-control-lg"
                                        placeholder="ناوی بەکارهێنەر" type="text" required>
                                </div>

                                <div class="col-6 my-1">
                                    <label> وشەنهێنی </label>
                                    <input v-model="selected_users.user_password" class="form-control form-control-lg"
                                        placeholder="ناوی بەکارهێنەر" type="text" required>
                                </div>


                                <div class="col-6 my-1">
                                    <label> ڕۆڵ </label>
                                    <select v-model="selected_users.user_role" class="form-control" required>
                                        <option value="super">بەڕێوەبەر</option>
                                        <option value="admin">ژمێریار</option>
                                        <option value="company">کۆمپانیا</option>
                                        <option value="casher">فرۆشیار</option>
                                    </select>
                                </div>
                                <div class="col-6 my-1">
                                    <label> مەخزەن </label>
                            <select v-model="selected_users.store_id" class="form-control" required>
                                <option :value="item.store_id" v-for="(item, index) in store" :key="index">{{item.store_name}}</option>
                            </select>
                                </div>




                                <div class="col-6 my-1">
                                    <label>ژمارەی مۆبایل </label>
                                    <input v-model="selected_users.user_phone" class="form-control form-control-lg"
                                        placeholder="ناوی بەکارهێنەر" type="text" required>
                                </div>


                                <div class="col-6 my-1">
                                    <label> ئەکتیڤیتی </label>
                                    <select v-model="selected_users.user_status" class="form-control" required>
                                        <option>active</option>
                                        <option>deactive</option>
                                    </select>
                                </div>



                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_users: {},
                users: {},
                resetable : false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },
        computed: {
            allusers() {
                return this.$store.getters.users
            },
            store(){
                return this.$store.getters.store
            }
        },
        methods: {
            getusers() {
                $('#dataTable').DataTable().destroy();
                axios.get('admin/read.php')
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.$store.state.users = r.data.data
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            adduser() {
                let params = this.users
                axios.post('admin/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                           if (this.resetable) {
                           this.users = {};
                            }
                                this.getusers()
                            this.msg = {
                                model: true,
                                text: 'بەکارهێنەر زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deleteuser() {
                let params = {
                    user_id: this.selected_users.user_id
                }
                axios.post('admin/delete.php', params)
                    .then(r => {
                        console.log(r);
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getusers()
                            this.msg = {
                                model: true,
                                text: ' بەکارهێنەر سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updateuser() {
                let params = this.selected_users
                axios.post('admin/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getusers()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'بەکارهێنەر نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            openuser(item, type) {
                this.selected_users = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            this.getusers()
        },
    }
</script>